import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import './App.css';
import PrivateRoutes from './components/privateroute/PrivateRoute';
import SideBar from './components/sidebar/SideBar';
import LoginAd from './pages/Login';
import { useState } from 'react';
import Dashboard from './pages/Dashboard';
import FAQ from './pages/FAQ';
import AddFAQ from './pages/AddFAQ';
import EditFAQ from './pages/EditFAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EditPrivacyPolicy from './pages/EditPrivacyPolicy';
import Payment from './pages/Payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TransferForm from './pages/Transfer';
import Session from './pages/Session';
import Providers from './pages/Providers';
import Users from './pages/Users';
import UserForm from './components/userform/UserForm';
import AddUser from './pages/AddUser';
const stripePromise = loadStripe('pk_test_51PbGaq2MdK9zKYLFnKik3fUASsC1IZrakJSmbbEVRrPQr4wTaISncfGsgOqWjRHZaIbFvjUfRBUYrwU30QocKpm60094E6MJcO');


function App() {
  const [heading, setHeading] = useState('')

  return (
    <UserProvider>
      <Elements stripe={stripePromise}>

        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginAd />} />
            <Route exec element={<PrivateRoutes />}>
              <Route element={<SideBar heading={heading} />}>
                <Route path="/" element={<Dashboard setHeading={setHeading} />} />
                <Route path="/users" element={<Users setHeading={setHeading} />} />
                <Route path="/users/add-user" element={<AddUser setHeading={setHeading} />} />
                <Route path="/providers" element={<Providers setHeading={setHeading} />} />
                <Route path="/faq" element={<FAQ setHeading={setHeading} />} />
                <Route path="/faq/add" element={<AddFAQ setHeading={setHeading} />} />
                <Route path="/faq/edit/:id" element={<EditFAQ setHeading={setHeading} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy setHeading={setHeading} />} />
                <Route path="/privacy-policy/edit" element={<EditPrivacyPolicy setHeading={setHeading} />} />
                <Route path="/payment" element={<Payment setHeading={setHeading} />} />
                <Route path="/transfer" element={<TransferForm setHeading={setHeading} />} />
                <Route path="/session" element={<Session setHeading={setHeading} />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Elements>

    </UserProvider>
  );
}

export default App;