import React, { useEffect, useState } from 'react';
import style from './dashboardtable.module.css';
import { Modal, Button, Form, InputGroup, Table } from 'react-bootstrap';
import Pagination from '../pagination/Pagination';
import { getAllDoctor, updateDoctorApproval } from '../../services/ProviderService';
import Loading from '../loading/Loading';
import { FaUserCircle } from 'react-icons/fa';
import success from '../../assets/Success.png'

const DashboardTable = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [number, setNumber] = useState(3);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [successApproveModal, setSuccessApproveModal] = useState(false)
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [approvalStatus, setApprovalStatus] = useState(null);

    const handleClose = () => {
        setShow(false);
        console.log('close');
    }
    const handleShow = (license) => {
        setSelectedLicense(license);
        setShow(true);
    }

    const params = {
        page: currentPage + 1,
        limit: number,
        search: searchQuery,
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const getUserData = async () => {
        try {
            setLoading(true);
            const params = {
                search: searchQuery.trim(),
                page: currentPage + 1, // API expects page to be 1-based
                limit: number,
            };
            const response = await getAllDoctor(params);
            setData(response?.doctors);
            setTotalItems(response?.total);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        getUserData();
    }, [currentPage, number, searchQuery]);

    const handleApproval = async (record, status) => {
        const approvalData = {
            bcbaCertiApprove: status,
            doctorId: record
        };
        try {
            setLoading(true);
            await updateDoctorApproval(approvalData);
            setApprovalStatus(status);
            setLoading(false);
            setSuccessApproveModal(true);
            getUserData();
        } catch (error) {
            console.error('Failed to update approval status:', error);
        }
    };

    const reformatDate = (dateStr) => {
        if (!dateStr) return '-';

        const dateObj = new Date(dateStr);
        if (isNaN(dateObj.getTime())) return '-';

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return `${month} ${day}, ${year}`;
    };

    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 mt-2'>
                <div className='d-sm-flex gap-3 align-items-center justify-content-between mt-sm-4 mb-sm-3 mb-2'>
                    <InputGroup className={`${style.searchbar} mt-sm-0 mt-1`}>
                        <InputGroup.Text id="inputGroup-sizing-default" className={style.searchButton}>
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.875 21.1875L17.625 15.9375C18.8438 14.3438 19.5938 12.375 19.5938 10.2188C19.5938 5.0625 15.375 0.84375 10.2188 0.84375C5.0625 0.84375 0.75 5.0625 0.75 10.2188C0.75 15.375 4.96875 19.5938 10.125 19.5938C12.2812 19.5938 14.3438 18.8438 15.9375 17.5312L21.1875 22.7813C21.375 22.9688 21.75 23.1562 22.0312 23.1562C22.3125 23.1562 22.5938 23.0625 22.875 22.7813C23.3438 22.4063 23.3438 21.6562 22.875 21.1875ZM3.09375 10.2188C3.09375 6.375 6.28125 3.1875 10.125 3.1875C13.9688 3.1875 17.1562 6.375 17.1562 10.2188C17.1562 14.0625 13.9688 17.25 10.125 17.25C6.28125 17.25 3.09375 14.1563 3.09375 10.2188Z" fill="black" />
                            </svg>
                        </InputGroup.Text>
                        <Form.Control
                            type='text'
                            className={style.inputfieldsearch}
                            placeholder='Search by name'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                    <Table responsive borderless>
                        <thead>
                            <tr className={`${style.rowheading} ${style.section1}`}>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Gender</th>
                                <th>Experience</th>
                                <th>Date of Birth</th>
                                <th>BCBA Certificate</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody className={style.tabledata}>
                            {
                                data?.length > 0 ?
                                    data.map((record, index) => (
                                        <tr key={index}>
                                            <td className='text-center'>{record?.profileImg ? <img className={style.userprofile} src={record?.profileImg?.url} alt="User Profile" /> : <FaUserCircle className={style.userprofile} />}</td>
                                            <td>
                                                {record?.firstName} {record?.lastName}
                                            </td>
                                            <td>{record?.email}</td>
                                            <td>{record?.gender ?? '-'}</td>
                                            <td>{record?.experience ?? '-'}</td>
                                            <td>{reformatDate(record?.dateOfBirth)}</td>
                                            <td className='text-center d-flex gap-3 align-items-center justify-content-center'>
                                                {/* {record?.licenseImg ? (
                                                    <img
                                                        className={style.userprofile}
                                                        src={record?.licenseImg?.url}
                                                        alt="licenseImg"
                                                        onClick={() => handleShow(record.licenseImg)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                ) : (
                                                    <FaUserCircle className={style.userprofile} />
                                                )} */}
                                                <div>
                                                    {record?.certificate ? (
                                                        <span>{record?.certificate}</span>
                                                    ) : (
                                                        <span>No Certificate</span>
                                                    )}
                                                </div>
                                                <div className="gap-2">
                                                    <Button
                                                        size="sm"
                                                        variant={record.bcbaCertiApprove === true ? "success" : "outline-success"}
                                                        onClick={() => handleApproval(record._id, true)}
                                                        disabled={record.bcbaCertiApprove === true}
                                                        style={{ marginRight: '10px' }}
                                                    >
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant={record.bcbaCertiApprove === false ? "danger" : "outline-danger"}
                                                        onClick={() => handleApproval(record._id, false)}
                                                        disabled={record.bcbaCertiApprove === false}
                                                    >
                                                        Reject
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>{reformatDate(record?.createdAt)}</td>
                                        </tr>
                                    )) :
                                    <tr className='text-center'>
                                        <td colSpan={6}><h4 className='p-4'>Data Not Found</h4></td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    {totalItems > number ? ( // Only show pagination if there are more items than the limit
                        <Pagination
                            pageCount={Math.ceil(totalItems / number)} // Calculate total pages
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        />
                    ) : null}
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered className='modal-main'>
                <Modal.Header closeButton >
                    <Modal.Title>License Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLicense && (
                        <img
                            src={selectedLicense.url}
                            alt="licenseImg"
                            className="w-100"
                        />
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={successApproveModal} onHide={() => setSuccessApproveModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <img
                            src={success}
                            alt="licenseImg"
                            className="w-75"
                        />
                        <h5>
                            Doctor's Certificate {approvalStatus ? 'Approved' : 'Rejected'} Successfully!
                        </h5>
                        <Button
                            className={style.submitbutton}
                            onClick={() => {
                                setSuccessApproveModal(false);
                                setApprovalStatus(null);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DashboardTable;